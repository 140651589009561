import { useState, useEffect } from 'react';
import { fetchWithAuth } from './authHelpers';

export const useBoost = () => {
    const [boostNames, setBoostNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBoostNames = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/boosts/names`, 'GET');
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch boost names');
                }

                const data = await response.json();
                if (Array.isArray(data)) {
                    setBoostNames(data);
                } else {
                    console.error("Unexpected data format:", data);
                }
            } catch (error) {
                setError(error);
                console.error("Error fetching boost names:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBoostNames();
    }, []);

    return { boostNames, loading, error };
};