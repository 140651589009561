import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/HowToPlay.css';
import howToPlayContent from './HowToPlayContent.json';

const HowToPlay = () => {
    const views = Object.keys(howToPlayContent);
    const [view, setView] = useState(views[0]);

    useEffect(() => {
        const contentElement = document.querySelector('.howtoplay-content');
        if (contentElement) {
            contentElement.scrollTop = 0;
        }
    }, [view]);

    return (
        <div className={"main-container col-10 offset-1"}>
            <div className='title-container'>
                <div className='inventory-box'>
                    <h1 className='title'>How To Play</h1>
                </div>
            </div>

            <div className='market-container'>
                <div className="market-filter col-2">
                    <h1 className='title-inventory'>Navigation</h1>
                    <div className='howtoplay-nav-inner'>
                        {/* Nav */}
                        {views.map((v, i) => (
                            <button
                                key={i}
                                className={`default-button ${view === v ? 'active' : ''}`}
                                onClick={() => setView(v)}
                            >
                                {v.replace(/([A-Z])/g, ' $1').trim()}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='howtoplay-container'>
                    <h1 className='title'>{view.replace(/([A-Z])/g, ' $1').trim()}</h1>
                    <div className='howtoplay-content'>
                        {howToPlayContent[view].map((section, index) => (
                            <div className='howtoplay-paragraph' key={index}>
                                <h2>{section.title}</h2>
                                <p dangerouslySetInnerHTML={{ __html: section.content }}></p>
                                {section.table && (
                                    <table className="howtoplay-table">
                                        <thead>
                                            <tr>
                                                <th>Boost Name</th>
                                                <th>Description</th>
                                                <th>Bronze</th>
                                                <th>Silver</th>
                                                <th>Gold</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {section.table.map((boost, idx) => (
                                                <tr key={idx}>
                                                    <td>{boost.name}</td>
                                                    <td>{boost.description}</td>
                                                    <td>{boost.rarities[0].range}</td>
                                                    <td>{boost.rarities[1].range}</td>
                                                    <td>{boost.rarities[2].range}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                                <hr />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToPlay;