import React, { useState } from 'react';
import '../hooks/fetchCard';
import '../css/FantasyCardStats.css'; // Create a CSS file for styling
import Card from './Card';

const FantasyCardStats = ({ fantasyCard, onClose, teamImages }) => {
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(null);

  const toggleMatchStats = (index) => {
    setSelectedMatchIndex(selectedMatchIndex === index ? null : index);
  };

  const getPointsStyle = (point) => {
    return {
      color: point < 0 ? 'var(--lightred)' : point > 0 ? 'var(--lightgreen)' : 'inherit'
    };
  };

  const getRatingStyle = (rating) => {
    return {
      color: rating < 1 ? 'var(--lightred)' : rating > 1 ? 'var(--lightgreen)' : 'inherit'
    };
  };

  if (!fantasyCard || !fantasyCard.card) {
    return null;
  }

  const getMatchStatsStyle = (match) => {
    return {
      background: match.team.points < match.enemyTeam.points ? 'linear-gradient(145deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0.1) 50%, var(--bg1) 100%)' : 'linear-gradient(145deg, rgba(0, 255, 0, 0.2) 0%, rgba(0, 255, 0, 0.1) 50%, var(--bg1) 100%)'
    };
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className='overlay' onClick={handleOverlayClick}>
      <div className='overlay-container'>
      <h1 className='title-events'>Card Stats</h1>
      <button className='overlay-close' onClick={onClose}>X</button>
        <div className='overlay-content' onClick={(e) => e.stopPropagation()}>
          <div className='overlay-stats-container'>
            <Card cardID={fantasyCard && fantasyCard.card} />
            <div className='overlay-stats'>
              <p> <span className='overlay-font'> Matches</span> <span>:</span> <span> {fantasyCard.matches.length} </span> </p>
              <p> <span className='overlay-font'> Total Points</span> <span>:</span> <span style={getPointsStyle(fantasyCard.points)}> {fantasyCard.points.toFixed(2)} </span> </p>
              <p> <span className='overlay-font'> Total Boost Points</span> <span>:</span> <span style={getPointsStyle(fantasyCard.boostPoints)}> {fantasyCard.boostPoints.toFixed(2)} </span> </p>
              {fantasyCard?.boosts.map((boost, index) => (
                <p key={index}> <span className='overlay-font'> {boost.name} boost</span> <span>:</span> <span style={getPointsStyle(boost.value)}> {boost.value.toFixed(2)} </span> </p>
              ))}
            </div>
          </div>
          <div className='overlay-matches-container'>
            <h3 className='overlay-font'>Matches </h3>
            {fantasyCard?.matches.slice().reverse().map((match, index) => (
              <div key={index} className={`overlay-matches` + (selectedMatchIndex === index ? ' selected' : '')} style={getMatchStatsStyle(match)} onClick={() => toggleMatchStats(index)} >
                <p className='overlay-match-title'>
                  <img className='stats-team-image' src={teamImages[match.team.name]} alt={match.team.name} />
                  {match.team.name} {match.team.points} - {match.enemyTeam.points} {match.enemyTeam.name}
                  <img className='stats-team-image' src={teamImages[match.enemyTeam.name]} alt={match.enemyTeam.name} />
                </p>
                {selectedMatchIndex === index && (
                  <div className='match-stats'>
                    <p>Total Points Earned: <span style={getPointsStyle(match.points)}>{match.points.toFixed(2)}</span></p>
                    <p>Total Boost Points: <span style={getPointsStyle(match.boostPoints)}>{match.boostPoints.toFixed(2)}</span></p>
                    {match?.boostPointsDetails.map((boost, index) => (
                      <p key={index} style={{ fontSize: '14px' }}> {boost.boostName} Boost: <span style={getPointsStyle(boost.points)}>{boost.points.toFixed(2)}</span></p>
                    ))}
                    <p> Rating: <span style={getRatingStyle(match.stats.rating)}>{match.stats.rating}</span></p>
                    <p className='overlay-moreinfo' onClick={(e) => { e.stopPropagation(); window.open(`https://www.hltv.org/matches/${match.matchID}/-`, '_blank'); }}>More info</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FantasyCardStats;