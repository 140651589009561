import { useState, useEffect } from 'react';
import { fetchWithAuth } from './authHelpers';

export const useEvents = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchEvents = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/events`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch events');
            }

            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            sortedData.reverse();
            setEvents(sortedData);
        } catch (error) {
            setError("Error fetching events: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    return { events, loading, error };
};

export default useEvents;