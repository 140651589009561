import { useState, useEffect } from 'react';
import { fetchWithAuth } from "./authHelpers";

export const usePlayer = () => {
    const [playerNames, setPlayerNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlayerNames = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/players/names`, 'GET');
                const data = await response.json();
                if (Array.isArray(data)) {
                    setPlayerNames(data);
                } else {
                    console.error("Unexpected data format:", data);
                }
            } catch (error) {
                setError(error);
                console.error("Error fetching player names:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlayerNames();
    }, []);

    return { playerNames, loading, error };
};