const SortAcsendingLogo = (
    `
    <svg class="sort-asc-svg" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
        <g id="SVGRepo_iconCarrier">
            <path d="M12,2.25A9.75,9.75,0,1,0,21.75,12,9.761,9.761,0,0,0,12,2.25Zm0,18A8.25,8.25,0,1,1,20.25,12,8.259,8.259,0,0,1,12,20.25ZM16.75,16a.75.75,0,0,1-.75.75H8a.75.75,0,0,1,0-1.5h8A.75.75,0,0,1,16.75,16Zm-8-4a.75.75,0,0,1,.75-.75h5a.75.75,0,0,1,0,1.5h-5A.75.75,0,0,1,8.75,12Zm1.5-4A.75.75,0,0,1,11,7.25h2a.75.75,0,0,1,0,1.5H11A.75.75,0,0,1,10.25,8Z" />
        </g>
    </svg>
    `
)

export default SortAcsendingLogo;