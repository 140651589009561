import { useState, useEffect } from 'react';
import { fetchWithAuth } from './authHelpers';

const useTeamImages = (matches) => {
  const [teamImages, setTeamImages] = useState({});

  useEffect(() => {
    const fetchTeamImages = async () => {
      const images = {};
      for (const match of matches || []) {
        const teamNames = [match.enemyTeam.name, match.team.name];
        for (const teamName of teamNames) {
          if (!images[teamName]) {
            try {
              const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/teams/${teamName}`, 'GET');
              if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `Failed to fetch image for team ${teamName}`);
              }

              const data = await response.json();
              if (data && data.image) {
                images[teamName] = data.image;
              }
            } catch (error) {
              console.error(`Error fetching image for team ${teamName}:`, error);
            }
          }
        }
      }
      setTeamImages(images);
    };

    fetchTeamImages();
  }, [matches]);

  return teamImages;
};

export default useTeamImages;