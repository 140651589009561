import '../css/EventsFrontpage.css';
import '../css/WinnerTeam.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useMemo } from 'react';
import { useLastWinner } from '../hooks/useLastWinner';
import Card from './Card'; // Ensure you have a Card component to display the card details
import FantasyCardStats from './FantasyCardStats'; // Ensure you have a FantasyCardStats component to display the card stats
import useTeamImages from '../hooks/useTeamImages';
import { useNavigate } from 'react-router-dom';

const WinnerTeam = () => {
    const { lastWinner, event, loading, error } = useLastWinner();
    const [showOverlay, setShowOverlay] = useState(false); // State for overlay visibility
    const [overlayContent, setOverlayContent] = useState(null); // State for overlay content
    const navigate = useNavigate();

    const matches = useMemo(() => {
        if (lastWinner && lastWinner.cards) {
            return lastWinner.cards.map(card => card.matches).flat();
        }
        return [];
    }, [lastWinner]);

    const images = useTeamImages(matches);

    const getPointsStyle = (points) => {
        return { color: points < 1 ? 'var(--lightred)' : points > 1 ? 'var(--lightgreen)' : 'inherit' };
    };

    const cardStats = (fantasyCard) => {
        setOverlayContent(fantasyCard); // Set the card data to be displayed in the overlay
        setShowOverlay(true); // Show the overlay
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setShowOverlay(false); // Hide the overlay when clicking outside of it
        }
    };

    return (
        <>
            {!error && (
                <div className='teamWinner-container'>
                    <div className='teamWinner-title'>
                        <div className='teamWinner-title-wrapper'>
                            <h1 className='title-events hoverable' onClick={() => navigate(`/event/${event.hltv_id}`)}>Last Event<span style={{ fontFamily: "fantasy" }}>'</span>s Winner</h1>
                            <div className='teamWinner-info'>
                                <div className='teamWinner-box'>{lastWinner && lastWinner.user.username}</div>
                                <div className='teamWinner-box'>{lastWinner && <span style={getPointsStyle(lastWinner.points)}>{lastWinner.points.toFixed(0)}</span>}</div>
                            </div>
                        </div>
                    </div>
                    {loading && (
                        <div className='eventsBox'>
                            <div className='loader' />
                        </div>
                    )}
                    <div className='winnerTeamBox'>
                        <h4 className='winnerTeam-eventname'>{event && event.name}</h4>
                        {lastWinner && (
                            <div className="cards-container" style={{ height: '95%' }}>
                                {Array.from({ length: 5 }).map((_, index) => {
                                    const card = lastWinner.cards.find(c => c.position === index + 1);
                                    return (
                                        <div key={index} className='event-fantasy-player' style={{ height: '100%' }}>
                                            {card ? (
                                                <div className='winnerTeam-card-container' onClick={() => cardStats(card)}>
                                                    <div className='card-hoverable' style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Card cardID={card.card} scale={0.5} />
                                                    </div>
                                                    <div className='points-box'>
                                                        <p className='winnerTeam-card-points'>
                                                            <span style={getPointsStyle(card.points)}>{Math.round(card.points)}</span> Points
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <img className='fantasy-player-image' src={'https://static.hltv.org/images/playerprofile/bodyshot/unknown.png'} alt='player' />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Render the overlay */}
            {showOverlay && (
                <div className='overlay' onClick={handleOverlayClick}>
                    <FantasyCardStats fantasyCard={overlayContent} onClose={() => setShowOverlay(false)} teamImages={images} />
                </div>
            )}
        </>
    );
};

export default WinnerTeam;