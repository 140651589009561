import { useState, useEffect } from 'react';
import { fetchWithAuth } from './authHelpers';

export const useTeam = () => {
    const [teamNames, setTeamNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeamNames = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/teams/names`, 'GET');
                const data = await response.json();
                if (Array.isArray(data)) {
                    setTeamNames(data);
                } else {
                    console.error("Unexpected data format:", data);
                }
            } catch (error) {
                setError(error);
                console.error("Error fetching team names:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamNames();
    }, []);

    return { teamNames, loading, error };
};