// src/components/CoinIcon.js
import React from 'react';

const CoinIcon = ({ width = "200px", height = "200px", fill = "#000000" }) => (
    <svg height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 491.52 491.52" xmlSpace="preserve" fill={fill}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <circle style={{ fill: "#df8f17" }} cx="245.76" cy="245.76" r="245.76"></circle>
            <path style={{ fill: "#f2d79c" }} d="M245.757,446.838c-110.875,0-201.076-90.205-201.076-201.076 c0-110.871,90.201-201.076,201.076-201.076c110.876,0,201.076,90.206,201.076,201.076 C446.834,356.634,356.634,446.838,245.757,446.838z"></path>
            <path style={{ fill: "#df8f17" }} d="M239.204,369.291v-21.755c-27.223-2.789-51.658-13.605-73.315-32.498l21.755-25.925 c16.697,14.503,33.88,23.16,51.561,25.925v-57.817c-22.458-5.36-38.889-12.393-49.317-21.148 c-10.428-8.731-15.654-21.706-15.654-38.901c0-17.171,6.014-31.237,18.032-42.15c12.016-10.938,27.672-16.904,46.94-17.898v-14.89 h19.074v15.206c21.851,1.576,42.515,9.046,61.989,22.336l-19.378,27.429c-13.108-9.337-27.32-15.108-42.611-17.292v56.023h0.897 c22.846,5.36,39.628,12.636,50.36,21.755c10.731,9.142,16.091,22.409,16.091,39.797c0,17.389-6.16,31.528-18.48,42.466 c-12.32,10.914-28.606,16.88-48.869,17.874v21.463H239.204z M218.189,176.922c-5.056,4.462-7.591,10.04-7.591,16.686 c0,6.669,1.976,11.907,5.954,15.788c3.978,3.88,11.519,7.615,22.652,11.181v-51.56C230.266,169.817,223.258,172.46,218.189,176.922z M280.93,307.593c5.553-4.56,8.342-10.332,8.342-17.293c0-6.936-2.195-12.465-6.56-16.539c-4.365-4.05-12.514-7.882-24.434-11.472 v53.646C267.809,314.941,275.364,312.177,280.93,307.593z"></path>
        </g>
    </svg>
);

export default CoinIcon;