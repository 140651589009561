import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeContext } from '../context/ThemeContext';

const BackgroundVideo = () => {
  const { backgroundVideo } = useContext(ThemeContext);

  useEffect(() => {
  }, [backgroundVideo]);

  const handleVideoError = (e) => {
    console.error('Error loading video:', e);
    console.error('Video URL:', backgroundVideo);
  };

  return ReactDOM.createPortal(
    <video
      className='app-background-video'
      src={backgroundVideo}
      autoPlay
      loop
      muted
      onError={handleVideoError}
    />,
    document.getElementById('background-video-root')
  );
};

export default BackgroundVideo;