import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Leaderboard.css';
import useLeaderboard from '../hooks/useLeaderboard';
import useSeason from '../hooks/useSeason';
import FantasyTeamView from './FantasyTeamView';

const Leaderboard = ({ event, fullSize = false }) => {
    const { leaderboard, error, fetchLeaderboardForEvent, fetchLeaderboardForSeason, getUserId } = useLeaderboard();
    const { seasons, fetchSeasons } = useSeason();
    const [season, setSeason] = useState(null);
    const [eventRewards, setEventRewards] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null); // State to store the current user ID
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 10;
    const [selectedUserID, setSelectedUserID] = useState(null); // State for selected user ID
    const [showOverlay, setShowOverlay] = useState(false); // State for overlay visibility
    const [selectedEvent, setSelectedEvent] = useState(null); // State for selected team

    useEffect(() => {
        if (event) {
            fetchLeaderboardForEvent(event);
            setEventRewards(event.rewards);
        } else {
            if (season) {
                fetchLeaderboardForSeason(season._id);
            } else {
                fetchSeasons();
                fetchLeaderboardForSeason();
            }
        }
        // eslint-disable-next-line
    }, [event, season]);

    useEffect(() => {
        setSeason(seasons && seasons[0] && seasons[0]);
    }, [seasons]);

    // Fetch the current user ID when the component mounts
    useEffect(() => {
        const fetchCurrentUserId = async () => {
            const userId = await getUserId();
            setCurrentUserId(userId);
        };

        fetchCurrentUserId();
    }, [getUserId]);

    // Calculate the current entries to display
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = leaderboard ? leaderboard.slice(indexOfFirstEntry, indexOfLastEntry) : [];

    // Calculate total pages
    const totalPages = leaderboard ? Math.ceil(leaderboard.length / entriesPerPage) : 1;

    const openFantasyTeamView = (userID) => {
        setSelectedUserID(userID); // Set the selected user ID to fetch the fantasy team
        setShowOverlay(true); // Show the overlay
    };

    const closeOverlay = () => {
        setShowOverlay(false); // Hide the overlay
        setSelectedEvent(null); // Reset the selected event
    };

    const closeSelectedEvent = () => {
        setSelectedEvent(null);
    }

    // Find the index of the current user
    const currentUserIndex = leaderboard && leaderboard.findIndex(item => item._id === currentUserId) + 1;

    const goToPlacement = () => {
        if (currentUserIndex !== 0) {
            const pageNumber = Math.ceil((currentUserIndex + 1) / entriesPerPage);
            setCurrentPage(pageNumber);
        }
    }

    const getPointsStyle = (point) => {
        return {
            color: point < 0 ? 'var(--lightred)' : point > 0 ? 'var(--lightgreen)' : 'inherit'
        };
    };

    return (
        <div className={`main-container ${fullSize ? '' : 'col-10 offset-1'}`}>
            <div className='title-container'>
                <h1 className='title'>Leaderboard</h1>
                <div className="view-button-container">
                    {currentUserIndex > 0 &&
                        <button className='view-button' onClick={goToPlacement}>
                            Current Placement: {currentUserIndex}
                        </button>}
                </div>
                {!event && <div className='inventory-box-filter'>
                    <div className="dropdown">
                        <button id="dropdown-button" className="dropdown-button">{season && season.name}</button>
                        <div id="dropdown-content" className="dropdown-content">
                            {seasons ? (
                                seasons.map((season, index) => (
                                    <p key={index} onClick={() => setSeason(season)}>{season.name}</p>
                                ))
                            ) : (
                                <div className='loader' />
                            )}
                        </div>
                    </div>
                </div>}
            </div>
            <div className='leaderbord-container'>
                <div className='leaderboard-desc-container'>
                    <h4 className='col-1' style={{ fontFamily: "inherit" }}>#</h4>
                    <h4 className='col-2'>User</h4>
                    <h4 className='col-2'>Fantasy Points</h4>
                    {event && <h4 className='col-2'>Boost Points</h4>}
                    {event && <h4 className='col-2'>Rewards</h4>}
                    {!event && <h4 className='col-2'>Events Won</h4>}
                    {!event && <h4 className='col-2'>Events Participated</h4>}
                </div>
                <div className='leaderboard-content'>
                    {leaderboard ? (
                        currentEntries.map((item, index) => (
                            <div
                                className={`leaderboard-entry-container ${item._id === currentUserId ? 'highlight' : ''}`}
                                key={index} onClick={() => openFantasyTeamView(item._id)}
                            >
                                <p className='col-1'> #{indexOfFirstEntry + index + 1}</p>
                                <p className='col-2'>{item.username}</p>
                                <p className='col-2'>{Math.round(item.points)}</p>
                                {event && <p className='col-2'>{item.boostPoints && Math.round(item.boostPoints)}</p>}
                                {event && eventRewards && <p className='col-2'>{eventRewards[index].coins} Coins</p>}
                                {!event && <p className='col-2'>{item.winCount ? item.winCount : 0}</p>}
                                {!event && <p className='col-2'>{item.teamCount}</p>}
                            </div>
                        ))

                    ) : (
                        error ? (
                            <div className='no-packs-container'>
                                <p>No Entries Fetched</p>
                            </div>
                        ) : (
                            <div className='no-packs-container'>
                                <div className='loader' />
                            </div>
                        )
                    )
                    }
                </div>
                <div className='pagination'>
                    <button className='default-button'
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1 || totalPages === 0}
                    >
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button className='default-button'
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages || totalPages === 0}
                    >
                        Next
                    </button>
                </div>
            </div>

            {event && showOverlay && (
                <FantasyTeamView userID={selectedUserID} event={event ? event : null} onClose={closeOverlay} />
            )}

            {season && showOverlay && (
                <div className="overlay" onClick={closeOverlay}>
                    <div className='card-menu-container' style={{ height: "100%" }}>
                        <h1 className='title-eventpage'>{leaderboard[0].username}<span style={{ fontFamily: "fantasy" }}>'</span>s Fantasy Teams for {season.name}</h1>
                        <button className="overlay-close" onClick={closeOverlay}>X</button>
                        <div className='overlay-content' onClick={(e) => e.stopPropagation()}>
                            <div className='event-desc-container'>
                                <p className='col-7'> Event </p>
                                <p className='col-3'> Points </p>
                                <p className='col-2'> Placement </p>
                            </div>

                            <div className='leaderboard-scroll-container'>
                                {leaderboard && leaderboard.map((item, index) => (
                                    // Display all the leaderboard entries with the same id as the selected user
                                    item._id === selectedUserID && (
                                        item.teams && item.teams.map((team, index) => (
                                            <div key={index} className="event-entry-container" onClick={() => setSelectedEvent(team.event)} >
                                                <p className='col-7'> {team.event.name} </p>
                                                <p className='col-3' style={getPointsStyle(team.points)}> {team.points.toFixed(2)} </p>
                                                <p className='col-2'> {team.placement ? team.placement : "?"} </p>
                                            </div>
                                        ))
                                    )
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            )}
            {selectedEvent && (
                <FantasyTeamView userID={selectedUserID} event={selectedEvent} onClose={closeSelectedEvent} />
            )}
        </div>
    );
};

export default Leaderboard;