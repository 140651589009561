import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useLogIn = () => {
    const [logInError, setError] = useState(null);
    const [logInLoading, setLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const logIn = async (username, password) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password
                }),
                credentials: 'include' // Include cookies in the request
            });

            if (response.ok) {
                const json = await response.json();
                // Update auth context
                dispatch({
                    type: 'LOGIN',
                    payload: json
                });
            } else {
                const data = await response.json();
                throw new Error(data.message);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }
    return { logInError, logInLoading, logIn };
}