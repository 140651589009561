import { useState } from 'react';
import { fetchWithAuth } from './authHelpers';

export const useSeason = () => {

    const [seasons, setSeasons] = useState(null);

    const fetchSeasons = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/seasons`, 'GET');

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch seasons');
            }

            const seasons = await response.json();
            setSeasons(seasons.reverse());
        } catch (err) {
            console.error("Error fetching seasons:", err.message);
        }
    }

    return { seasons, fetchSeasons };
};

export default useSeason;