// Function to make authenticated fetch requests
export const fetchWithAuth = async (url, method, body = null) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    };
    if (body) {
        options.body = JSON.stringify(body);
    }

    return await fetch(url, options);
};

export const getToken = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user).Token; // Corrected JSON parsing
    } else {
        console.error('No user found');
        return null;
    }
};

// Function to get the user ID from local storage
export const getUserId = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user).userID; // Corrected JSON parsing
    } else {
        console.error('No user found');
        return null;
    }
};