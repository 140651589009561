import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useEvent from '../hooks/useEvent';
import Flag from 'react-world-flags';
import Inventory from './Inventory';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/EventPage.css';
import '../css/EventsFrontpage.css';
import '../css/Card.css';
import '../index.css';
import Card from './Card';
import Leaderboard from './Leaderboard';
import FantasyCardStats from './FantasyCardStats';

const EventPage = () => {
  const { event_id } = useParams();
  const { event, loading, error, fantasyTeam, saveError, saveSuccess, handleSaveTeam, lockEvent } = useEvent(event_id);
  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState('');
  const [view, setView] = useState('fantasy'); // Single state variable for view
  const [team, setTeam] = useState([]);
  const [allowedPlayers, setAllowedPlayers] = useState([]);
  const [lock, setLock] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // State for overlay visibility
  const [overlayContent, setOverlayContent] = useState(null); // State for overlay content
  const [teamImages, setTeamImages] = useState({}); // State for preloaded team images

  useEffect(() => {
    if (titleRef.current && imageRef.current) {
      const titleHeight = titleRef.current.offsetHeight;
      imageRef.current.style.height = `${titleHeight}px`;
    }
  }, [event]);

  useEffect(() => {
    if (event && event.isLive) {
      setTimeLeft('LIVE');
      setLock(true);
    }
    if (event && event.isFinished) {
      setTimeLeft('Finished');
      setLock(true);
    }
  }, [event]);

  useEffect(() => {
    if (event && event.startDate) {
      const targetDate = new Date(event.startDate).getTime();

      const updateTimer = () => {
        const now = new Date().getTime();
        const distance = targetDate - now;

        if (distance < 0) {
          clearInterval(interval);
          if (!event.isLive) {
            lockEvent(event_id);
          }
          return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      };

      setAllowedPlayers(event.eventPlayers);

      const interval = setInterval(updateTimer, 1000);
      updateTimer();

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [event]);

  useEffect(() => {
    if (fantasyTeam) {
      // Map each card to its correct position
      const updatedTeam = (fantasyTeam.cards || []).map(card => ({
        ...card,
        position: card.position
      }));
      setTeam(updatedTeam);
    }
    // eslint-disable-next-line
  }, [fantasyTeam]);

  useEffect(() => {
    if (event && event.eventTeams) {
      const images = {};
      event.eventTeams.forEach(team => {
        images[team.name] = team.image;
      });
      setTeamImages(images);
    }
  }, [event]);

  if (loading) {
    return <div className='loader'></div>;
  }

  if (error) {
    console.error(error);
    return <div className='offset-1 error'>{error}</div>;
  }

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }

    return date.toLocaleDateString('da-DK', options);
  }

  const formatMatchDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    const options = {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    const formattedDate = date.toLocaleDateString('da-DK', options).split('.');
    return `${formattedDate[0]}/${formattedDate[1]}:${formattedDate[2]}`;
  };

  const addCard = (card) => {
    setTeam(prevTeam => {
      if (prevTeam.length >= 5) { return prevTeam; }
      if (prevTeam.find(c => c.card._id === card._id)) { return prevTeam; }
      if (prevTeam.find(c => c.player && c.player._id === card.player._id)) { return prevTeam; }


      // Find the first available position between 1 and 5
      let position = [1, 2, 3, 4, 5].find(p => !prevTeam.find(c => c.position === p));

      // Add the card to the team with the assigned position
      return [...prevTeam, { card: card._id, player: card.player._id, points: 0, position: position /**currentTeam: somehow get the current team */ }];
    });
  };

  const removeCard = (card) => {
    setTeam(prevTeam => {
      const updatedTeam = prevTeam.filter(c => c.card !== card.card);
      return updatedTeam;
    });
  };

  const cardStats = (fantasyCard) => {
    setOverlayContent(fantasyCard); // Set the card data to be displayed in the overlay
    setShowOverlay(true); // Show the overlay
  };

  const getPointsStyle = (point) => {
    return {
      color: point < 0 ? 'var(--lightred)' : point > 0 ? 'var(--lightgreen)' : 'inherit'
    };
  };

  const getNextMatch = (teamID) => {
    if (!teamID || teamID._id === undefined) return null;
    const team = event.teamsInFantasy.find(t => t.team._id === teamID._id);
    if (!team) {
      return null;
    }
    if (team.placement !== null && team.placement !== undefined) {
      console.log(team.placement);
      return { placement: team.placement };
    } else if (team.nextMatch && team.nextMatch.length > 0) {
      console.log(team.nextMatch[team.nextMatch.length - 1]);
      return team.nextMatch[team.nextMatch.length - 1];
    } else {
      return null;
    }
  };

  const renderNextMatchOrPlacement = (nextMatch) => {
    if (!nextMatch) return null;
    if (nextMatch.placement !== undefined) {
      return (
        <div className='event-points-box'>
          <p className='card-points'>
            Placement: {nextMatch.placement}
          </p>
        </div>
      );
    }
    if (nextMatch.startTime + 1000 * 60 * 60 < Date.now()) {
      return (
        <div className='event-points-box-nextMatch'>
          <p className='card-points'>
            No match scheduled
          </p>
        </div>
      );
    }

    return (
      <div className='event-points-box-nextMatch'>
        <p className='card-points'>
          {nextMatch.opponent.name} &nbsp;-&nbsp; {formatMatchDate(nextMatch.startTime)}
        </p>
      </div>
    );
  };

  return (
    <div className='main-container col-10 offset-1'>
      <div className='scroll-container'>
        <div className='event-page-container'>
          {timeLeft !== "LIVE" && (<p className='timer-eventpage'>{timeLeft}</p>)}
          {timeLeft === "LIVE" && (<p className='timer-eventpage live-eventpage'>{timeLeft}</p>)}
          <div className='eventpage-info'>
            <div className='col-auto'>
              <img ref={imageRef} className='eventpage-image' src={event.image} alt={event.name} />
            </div>
            <div className='col-6'>
              <h1 ref={titleRef} className='title-eventpage'>{event.name}</h1>
            </div>
          </div>
          <div className='eventpage-desc col-12'>
            {event.startDate && <p className='event-date'> Date: {formatDate(event.startDate)} - {formatDate(event.endDate)}</p>}
            {event.location && event.location[0] && (
              <p className='event-location'>Location: <Flag className='flag' code={event.location[0].code} /> {event.location[0].name}</p>
            )}
            {event.prizePool && <p className='event-prizepool'>Prize pool: {event.prizePool}</p>}
            {event.eventTeams && (
              <div className='event-teams'>
                <p>Teams:</p>
                {event.eventTeams.map(team => (
                  <img key={team.name} src={team.image} alt={team.name} className='eventpage-team-image' />
                ))}
              </div>
            )}
          </div>
          <div className='eventpage-selct-container'>
            <div className="col-auto">
              <button className={`default-button ${view === 'fantasy' ? 'active' : ''}`} onClick={() => setView('fantasy')}>Fantasy</button>
            </div>
            <div className="col-auto">
              <button className={`default-button ${view === 'leaderboard' ? 'active' : ''}`} onClick={() => setView('leaderboard')}>Leaderboard</button>          </div>
          </div>
        </div>

        {view === 'fantasy' && (
          <div>
            <div className='event-page-container'>
              <h1 className='title-eventpage'>Fantasy Team</h1>
            </div>
            <div className='event-page-team-container'>
              <div className='event-fantasy-team-container'>
                {Array.from({ length: 5 }).map((_, index) => {
                  const card = team.find(c => c.position === index + 1);
                  return (
                    <div key={index} className='event-fantasy-player'>
                      {card ? (
                        <div className='card-point-container' onClick={() => { !lock ? removeCard(card) : cardStats(card) }}>
                          {lock && renderNextMatchOrPlacement(getNextMatch(card.currentTeam))}
                          <Card cardID={card.card} />
                          {lock && (
                            <div className='event-points-box'>
                              <p className='card-points'>
                                <div><span style={getPointsStyle(card.points)}>{Math.round(card.points)}</span> Points</div>
                                {/*(<span style={getPointsStyle(card.lastPoints)}>
                                {card.lastPoints && Math.round(card.lastPoints)}
                              </span> Points)*/}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <img className='fantasy-player-image' src={'https://static.hltv.org/images/playerprofile/bodyshot/unknown.png'} alt='player' />
                      )}
                    </div>
                  );
                })}
              </div>
              {!lock && <button className='default-button submit-team-button' onClick={() => handleSaveTeam(event_id, team)}>Save Team</button>}
              {lock && fantasyTeam && (
                <div className='event-teampoints-container'>
                  <div className='event-points-box total-points'>
                    <p>
                      Total points:
                      <span style={getPointsStyle(fantasyTeam.points)}> {fantasyTeam.points.toFixed(2)}</span>
                    </p>
                  </div>
                  <div className='event-points-box total-points'>
                    <p>
                      Boost points:
                      <span style={getPointsStyle(fantasyTeam.points)}> {fantasyTeam.boostPoints.toFixed(2)}</span>
                    </p>
                  </div>
                </div>
              )}
              {saveError && <p className='error' style={{ alignSelf: 'center' }}>{saveError}</p>}
              {saveSuccess && <p className='success' style={{ alignSelf: 'center' }}>{saveSuccess}</p>}
            </div>

            <div className='event-page-inventory-container' style={{ position: 'relative' }}>
              <Inventory onCardClick={!lock ? addCard : undefined} locked={!lock ? false : true} fullSize={true} allowedPlayers={allowedPlayers} team={team} />
            </div>
          </div>
        )}

        {view === 'leaderboard' && (
          <div>
            <Leaderboard event={event} fullSize={true} />
          </div>
        )}

        {/* Render the overlay */}
        {showOverlay && (
          <FantasyCardStats fantasyCard={overlayContent} onClose={() => setShowOverlay(false)} teamImages={teamImages} />
        )}
      </div>
    </div>
  );
};

export default EventPage;