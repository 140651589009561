import { fetchWithAuth } from "./authHelpers";

export const useBank = () => {
    const fetchBank = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/banks/current`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch bank');
            }

            const data = await response.json();
            return data
        } catch (error) {
            console.error('Error fetching bank:', error);
        }
    };

    const fetchCoins = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/banks/current/coins`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch coins');
            }

            const data = await response.json();
            return data
        } catch (error) {
            console.error('Error fetching coins:', error);
        }
    }

    const subtractCoins = async (subtractCoins) => {
        const coins = fetchCoins();
        if (coins < subtractCoins) {
            return false;
        }

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/banks/current/subtractCoins`, 'PATCH', { coins: subtractCoins });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to subtract coins');
            }

            await response.json();
            return true;
        } catch (error) {
            console.error('Error subtracting coins:', error);
            return false;
        }
    }

    const addCoins = async (addCoins) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/banks/current/addCoins`, 'PATCH', { coins: addCoins });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to add coins');
            }

            await response.json();
            return true;
        } catch (error) {
            console.error('Error adding coins:', error);
            return false;
        }
    }

    const sendCoins = async (recipientID, amount) => {
        const coins = fetchCoins();
        if (coins < amount) {
            return false;
        }

        try {
            const subtractResponse = await fetchWithAuth(
                `${process.env.REACT_APP_BACKEND_URL}/banks/current/subtractCoins`,
                'PATCH',
                { coins: amount }
            );
            if (!subtractResponse.ok) {
                const errorData = await subtractResponse.json();
                throw new Error(errorData.error || 'Failed to subtract coins');
            }

            const additionResponse = await fetchWithAuth(
                `${process.env.REACT_APP_BACKEND_URL}/banks/${recipientID}/addCoins`,
                'PATCH',
                { recipientID, coins: amount }
            );
            if (!additionResponse.ok) {
                const errorData = await additionResponse.json();
                throw new Error(errorData.error || 'Failed to add coins');
            }

            return true;
        } catch (error) {
            console.error('Error sending coins:', error);

            // Rollback: Add the coins back to the sender's account if additionResponse fails
            try {
                await fetchWithAuth(
                    `${process.env.REACT_APP_BACKEND_URL}/banks/current/addCoins`,
                    'PATCH',
                    { coins: amount }
                );
            } catch (rollbackError) {
                console.error('Error during rollback:', rollbackError);
            }

            return false;
        }
    };

    return { fetchBank, fetchCoins, subtractCoins, addCoins, sendCoins };
}