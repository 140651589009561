import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuickSell } from '../hooks/useQuickSell';
import '../css/CardMenu.css';
import CoinIcon from './CoinIcon';

const CardMenu = ({ card, onClose, updateInventoryCache }) => {
    const [quickSellPrice, setQuickSellPrice] = useState(0);
    const { quickSell, error, fetchCardFantasyTeamStatus } = useQuickSell(updateInventoryCache);
    const navigate = useNavigate();
    const [marketSellError, setMarketSellError] = useState(null);

    useEffect(() => {
        if (card) {
            setQuickSellPrice(card.tier * card.stats[0].firepower);
        }
    }, [card]);

    const handleQuickSell = async () => {
        const wasQuickSold = await quickSell(card._id);
        if (wasQuickSold) {
            onClose();
        }
    };

    const handleSell = async () => {
        const cardFantasyTeamStatus = await fetchCardFantasyTeamStatus(card._id);
        if (cardFantasyTeamStatus.status === 'ACTIVE') {
            setMarketSellError(`Can't sell card in active fantasy team`);
        }
        if (cardFantasyTeamStatus.status !== 'ACTIVE') {
            navigate(`/market/?player=${card.player.name}&rarity=${card.tier}&sellingCard=${card._id}`);
        }
    };

    return (
        <div className="card-menu">
            <div className='card-menu-container'>
                <h1 className='title-events'>Card Menu</h1>
                <button className="overlay-close" onClick={onClose}>X</button>
                <div className='card-menu-content'>
                    <div className='col-3 card-menu-option'>
                        <button className='default-button big' onClick={handleQuickSell}>
                            Quick Sell
                        </button>
                        <span className='subtitle-card-menu'> <CoinIcon width="36px" height="36px" /> <p> {quickSellPrice} </p> </span>
                    </div>
                    <div className='col-3 card-menu-option'>
                        <button className='default-button big' onClick={handleSell}>Create Listing</button>
                    </div>
                </div>
                {error && <p className='error' style={{ alignSelf: 'center' }}>{error}</p>}
                {marketSellError && <p className='error' style={{ alignSelf: 'center' }}>{marketSellError}</p>}
            </div>
        </div>
    );
}

export default CardMenu;