import { useState } from "react";
import { fetchWithAuth } from "./authHelpers";

export const useMarket = (cardID, coins) => {
    const [error, setError] = useState(null);

    const createListing = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings`, 'POST', { cardID: cardID, price: coins });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create listing');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error creating listing:', error);
            setError(error.message);
        }
    };

    const fetchCardFantasyTeamStatus = async (cardID) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${cardID}/fantasyTeamStatus`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch card fantasy teams');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching card fantasy team status:', error);
        }
    };

    const removeCard = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inventory/removeCard`, 'POST', { cardID });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove card');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error removing card:', error);
        }
    };

    const addListing = async () => {
        const data = await fetchCardFantasyTeamStatus(cardID);
        const cardFantasyTeamStatus = data.status;
        if (!cardFantasyTeamStatus) {
            return false;
        }
        if (cardFantasyTeamStatus === 'ACTIVE') {
            setError(`Can't sell card in active fantasy team`);
            return false;
        }
        if (cardFantasyTeamStatus !== 'ACTIVE') {
            const listing = await createListing();
            if (error) {
                return false;
            }
            await removeCard();
            if (error) {
                return false;
            }
            return listing;
        }
    };

    return { addListing, error };
};

export default useMarket;