import { fetchWithAuth } from "./authHelpers";

export const useCard = (cardID) => {

    const fetchCard = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${cardID}`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch card');
            }
            
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching card details:', error);
        }
    };

    return { fetchCard };
};