import React, { useEffect, useState } from 'react';
import '../css/CardMenu.css';
import CoinIcon from './CoinIcon';
import { useListing } from '../hooks/useListing';
import { useBank } from '../hooks/useBank';
import { useInventory } from '../hooks/fetchInventory';
import { useNavigate } from 'react-router-dom';
import useInbox from '../hooks/useInbox';

const MarketMenu = ({ listing, userID, onClose, onUpdateListing, onRemoveListing, setView }) => {
    const { removeListing, updateListing, deleteListing } = useListing();
    const { fetchBank, sendCoins } = useBank();
    const { addCardToInventory } = useInventory();
    const { addNotification } = useInbox(listing.owner._id); // Use the useInbox hook for the owner
    const [error, setError] = useState(null);
    const [changePrice, setChangePrice] = useState(false);
    const [sellPrice, setSellPrice] = useState(listing.price);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBank();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePrice = () => {
        setChangePrice(true);
    }

    const handleUpdatePrice = async () => {
        try {
            await updateListing(listing._id, sellPrice);
            const updatedListing = { ...listing, price: sellPrice };
            onUpdateListing(updatedListing);
            setChangePrice(false);
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    const handleRemoveListing = async () => {
        try {
            await removeListing(listing._id);
            await onRemoveListing(listing._id);
            onClose();
            setView('cards');
        } catch (error) {
            setError(error.message);
        }
    }

    const handleBuy = async () => {
        try {
            // Check if user has enough coins
            const price = listing.price;
            const bank = await fetchBank();
            if (bank.coins < price) {
                setError('Not enough coins');
                return;
            }

            // Remove listing
            await deleteListing(listing._id);

            // Update both user's coins
            await sendCoins(listing.owner._id, price);

            // Add card to user's collection
            await addCardToInventory(listing.card._id);

            // Send notification to the owner
            await addNotification(`Your card has been bought for ${price} coins.`);

            // Close menu and update listings
            await onRemoveListing(listing._id);
            await onClose();
        } catch (error) {
            setError(error.message);
        }
    }

    const handleComparePrice = async (listing) => {
        await onClose();
        await setView('cards');
        navigate(`/market/?listing=${listing._id}&player=${listing.card.player.name}&rarity=${listing.card.tier}`);
    }

    return (
        <div className="card-menu">
            <div className='card-menu-container'>
                <h1 className='title-events'>Card Menu</h1>
                <button className="overlay-close" onClick={onClose}>X</button>

                {listing.owner._id === userID && (
                    changePrice ? (
                        <div className='card-menu-content'>
                            <div>
                                <div style={{ alignItems: "center", display: "flex", flexDirection: "column", width: "100%" }}>
                                    <h1 className='title-card-menu' >
                                        New Price:
                                    </h1>
                                    <div className='market-sellbar-container' style={{ width: "100%", justifyContent: "space-between", margin: "20px" }}>
                                        <CoinIcon width='36px' height='36px' />
                                        <input
                                            className='market-sellbar'
                                            style={{ width: "65%", margin: "0", marginLeft: "10px", marginRight: "10px" }}
                                            type='number'
                                            value={sellPrice}
                                            placeholder='Price'
                                            min='1'
                                            max='1000000'
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    const value = Math.max(1, Math.min(1000000, Math.floor(Number(e.target.value))));
                                                    e.target.value = value;
                                                    setSellPrice(value);
                                                } else {
                                                    e.target.value = '';
                                                    setSellPrice(null);
                                                }
                                            }}
                                        />
                                        <button className='default-button' onClick={handleUpdatePrice}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='card-menu-content'>
                            <div className='col-3 card-menu-option'>
                                <button className='default-button big' onClick={handleRemoveListing}>
                                    Remove Listing
                                </button>
                            </div>
                            <div className='col-3 card-menu-option'>
                                <button className='default-button big' onClick={handleChangePrice}>
                                    Change Price
                                </button>
                            </div>
                            <div className='col-3 card-menu-option'>
                                <button className='default-button big' onClick={() => handleComparePrice(listing)}>
                                    Compare
                                </button>
                            </div>
                        </div>
                    )
                )}

                {listing.owner._id !== userID && (
                    <div className='card-menu-content'>
                        <div className='col-3 card-menu-option'>
                            <button className='default-button big' onClick={handleBuy}>
                                Buy
                            </button>
                            <span className='subtitle-card-menu'> <CoinIcon width="36px" height="36px" /> <p> {listing.price} </p> </span>
                        </div>
                        <div className='col-3 card-menu-option'>
                            <button className='default-button big' onClick={() => handleComparePrice(listing)}>Compare</button>
                        </div>
                    </div>
                )}

                {error && <p className='error' style={{ alignSelf: 'center' }}>{error}</p>}
            </div>
        </div>
    );
}

export default MarketMenu;