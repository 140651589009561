import React, { useState, useEffect, useRef } from 'react';
import '../css/Dropdown.css';

const Dropdown = ({ placeholder, query, zIndex, onSelect, selectedItem }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [internalSelectedItem, setInternalSelectedItem] = useState(selectedItem);

    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleItemClick = (item) => {
        if (item.name === 'None') {
            item = null;
        }
        setInternalSelectedItem(item);
        if (onSelect) {
            onSelect(item);
        }
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setInternalSelectedItem(selectedItem);
    }, [selectedItem]);

    const modifiedQuery = [{ name: 'None' }, ...query];

    return (
        <div className='dropdown-component-container' style={{ position: 'relative', zIndex: zIndex }} ref={dropdownRef}>
            <button className='dropdown-component' onClick={toggleDropdown}>
                <span className={!internalSelectedItem ? 'dropdown-placeholder' : ''}>
                    {internalSelectedItem ? internalSelectedItem?.name : placeholder ? placeholder : 'Select an item'}
                </span>
            </button>
            {isDropdownOpen && (
                <div className='dropdown-component-list' style={{ position: 'absolute', top: '100%', left: 0, zIndex: zIndex }}>
                    {modifiedQuery.map((item, index) => (
                        <div
                            key={index}
                            className='dropdown-component-item'
                            onClick={() => handleItemClick(item)}
                        >
                            <p>{item?.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;