import React, { useState } from 'react';

const SearchSuggestions = ({ query, searchTerm, setSearchTerm, suggestionsLength, zIndex }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (Array.isArray(query) && value.length > 0) {
            const filteredSuggestions = query.filter(query =>
                query.name.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            setSelectedSuggestionIndex(-1); // Reset the selected suggestion index
        } else {
            setSuggestions([]);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setSelectedSuggestionIndex(prevIndex => {
                const newIndex = Math.min(prevIndex + 1, suggestions.length - 1, suggestionsLength - 1);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            setSelectedSuggestionIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === 'Enter' && selectedSuggestionIndex >= 0 && suggestions[selectedSuggestionIndex]) {
            setSearchTerm(suggestions[selectedSuggestionIndex].name);
            setSuggestions([]);
        }
    };

    return (
        <div className='search-suggestions'>
            <input
                className='market-searchbar'
                style={{ zIndex: zIndex + 1 }}
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                onKeyDown={handleKeyDown}
                spellCheck="false"
                autoCorrect="off"
            />
            {suggestions.length > 0 && isInputFocused && (
                <div className='suggestions-list' style={{ zIndex: zIndex }}>
                    {suggestions.slice(0, suggestionsLength).map((suggestion, index) => (
                        <p
                            key={index}
                            onMouseDown={() => setSearchTerm(suggestion.name)}
                            className={index === selectedSuggestionIndex ? 'selected-suggestion' : ''}
                        >
                            {suggestion.name}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchSuggestions;