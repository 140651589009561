import { useState } from 'react';

export const useOpenPack = () => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const openPack = async (packID, tier) => {
        setLoading(true);
        setError(null); // Reset error state before making the request
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/packs/open`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ packID, tier }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to open pack');
            }

            const result = await response.json();
            setData(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const openDailyPack = async (packID) => {
        setLoading(true);
        setError(null); // Reset error state before making the request
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/packs/daily/open`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ packID }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to open pack');
            }

            const result = await response.json();
            setData(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { openPack, openDailyPack, data, error, loading };
};