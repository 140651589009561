import { useState, useEffect } from "react";
import { fetchWithAuth } from "./authHelpers";

export const usePack = () => {
    const [packs, setPacks] = useState([]);
    const [hasOpenedDailyPack, setHasOpenedDailyPack] = useState(false);
    const [dailyPackID, setDailyPackID] = useState(null);

    const fetchPacks = async () => {
        try {
            // Fetch packs
            const packsResponse = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/packs/active`, 'GET');
            if (!packsResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const packsData = await packsResponse.json();
            const sortedPacks = packsData.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            setPacks(sortedPacks);

            // Fetch daily pack status
            const dailyStatusResponse = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/packs/daily/status`, 'GET');
            if (!dailyStatusResponse.ok) {
                throw new Error('Network response was not ok');
            }
            
            const dailyStatusData = await dailyStatusResponse.json();
            setHasOpenedDailyPack(dailyStatusData.hasOpened);
            setDailyPackID(dailyStatusData.packID);
        } catch (error) {
            console.error('Error fetching packs or daily pack status:', error);
        }
    };

    useEffect(() => {
        fetchPacks();
    }, []);

    return { packs, hasOpenedDailyPack, dailyPackID };
};