import React, { createContext, useReducer, useEffect, useState } from 'react';

// Create the context
export const AuthContext = createContext();

// Reducer function for managing auth state
const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload };
        case 'LOGOUT':
            return { ...state, user: null };
        default:
            return state;
    }
};

// AuthContextProvider component
export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, { user: null });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                // Check if the user is authenticated by making a request to the backend
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/check-auth`, {
                    method: 'GET',
                    credentials: 'include' // Include cookies in the request
                });

                if (response.ok) {
                    const user = await response.json();
                    dispatch({ type: 'LOGIN', payload: user });
                } else {
                    dispatch({ type: 'LOGOUT' });
                }
            } catch (error) {
                console.error('Failed to fetch user:', error);
                dispatch({ type: 'LOGOUT' });
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    // Provide the context value
    return (
        <AuthContext.Provider value={{ ...state, loading, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};