import { useState, useEffect } from "react";
import { fetchWithAuth } from "./authHelpers";

export const useHltvNews = (initialLimit = 10) => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchNews = async (limit, retries = 3) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/news`, 'GET');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setNews(data);
        } catch (error) {
            if (retries > 0) {
                fetchNews(limit, retries - 1);
            } else {
                setError("Error fetching news: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNews(initialLimit);
        // eslint-disable-next-line
    }, [initialLimit]);

    return { news, loading, error };
};