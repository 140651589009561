import { useState } from "react";
import { fetchWithAuth } from "./authHelpers";

export const useQuickSell = (updateInventoryCache) => {
    const [error, setError] = useState(null);

    const fetchCardFantasyTeamStatus = async (cardID) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${cardID}/fantasyTeamStatus`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch card fantasy teams');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching card fantasy team status:', error);
        }
    };

    const quickSell = async (cardID) => {
        try {
            const data = await fetchCardFantasyTeamStatus(cardID);
            const cardFantasyTeamStatus = data.status;
            const cardValue = data.cardValue; // Use the cardValue from the response

            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${cardID}/quicksell`, 'POST', {
                cardValue,
                fantasyTeamStatus: cardFantasyTeamStatus
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to quicksell card');
            }

            const result = await response.json();
            if (updateInventoryCache) {
                updateInventoryCache(cardID);
            }
            return { ...result, cardValue }; // Return cardValue in the result
        } catch (error) {
            console.error('Error quickselling card:', error);
            setError(error.message);
            return false;
        }
    };

    const groupQuickSell = async (cardIDs) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/groupQuicksell`, 'POST', { cardIDs });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to quicksell cards');
            }

            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error during group quicksell:', error.message);
            return false;
        }
    };

    return { quickSell, groupQuickSell, error, fetchCardFantasyTeamStatus };
};

export default useQuickSell;