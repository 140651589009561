import { useState, useEffect } from 'react';
import { fetchWithAuth } from '../hooks/authHelpers';
export const useLastWinner = () => {
    const [lastWinner, setLastWinner] = useState(null);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getLastWinner = async () => {
            try {
                setLoading(true);
                const data = await fetchLastWinner();
                setLastWinner(data.winner);
                setEvent(data.event);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getLastWinner();
    }, []);

    const fetchLastWinner = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/events/last-winner`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch last winner');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching last winner:', error);
            throw error;
        }
    };

    return { lastWinner, event, loading, error };
};

export default useLastWinner;