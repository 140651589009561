import { useState, useEffect } from 'react';
import { fetchWithAuth } from '../hooks/authHelpers';

const useInbox = (userId) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                setLoading(true);
                const data = await getNotifications();
                console.log('Notifications:', data);
                if (Array.isArray(data)) {
                    setNotifications(data.reverse()); // Reverse the list of notifications
                } else {
                    setError('Failed to fetch notifications');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const getNotifications = async () => {
        try {
            console.log('Fetching notifications');
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inbox`, 'GET');
            console.log('Response:', response);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch notifications');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    };

    const addNotification = async (title) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inbox/${userId}`, 'POST', { title });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add notification');
            }
            const data = await response.json();
            setNotifications((prevNotifications) => [data, ...prevNotifications]); // Add new notification to the beginning of the list
        } catch (err) {
            setError(err.message);
        }
    };

    const removeNotification = async (notificationId) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inbox/${userId}/${notificationId}`, 'DELETE');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to remove notification');
            }
            setNotifications((prevNotifications) => prevNotifications.filter(notification => notification._id !== notificationId));
        } catch (err) {
            setError(err.message);
        }
    };

    const removeAllNotifications = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inbox/${userId}`, 'DELETE');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to remove all notifications');
            }
            setNotifications([]);
        } catch (err) {
            setError(err.message);
        }
    }

    return { notifications, loading, error, addNotification, removeNotification, removeAllNotifications };
};

export default useInbox;