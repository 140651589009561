import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from './Card';
import CoinIcon from './CoinIcon';
import MarketMenu from './MarketMenu';
import { useListing } from '../hooks/useListing';
import { useUser } from '../hooks/useUser';
import { useMarket } from '../hooks/useMarket';
import sortAscSVG from './SortAscendingLogo';
import sortDescSVG from './SortDescendingLogo';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Market.css';
import MarketFilter from './MarketFilter';

const Market = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const sellingCard = queryParams.get('sellingCard');
    const sellingCardPlayer = queryParams.get('player');
    const sellingCardTier = queryParams.get('rarity');
    const compareListinging = queryParams.get('listing');
    const [sellPrice, setSellPrice] = useState(0);
    const { listings, loading, fetchListings } = useListing();
    const { user } = useUser();
    const { addListing } = useMarket(sellingCard, sellPrice);
    const [currentUserId, setCurrentUserId] = useState(null);
    const listingCache = useRef(new Map());
    const [filteredListings, setFilteredListings] = useState([]);
    const [view, setView] = useState('cards');
    const [sortBy, setSortBy] = useState();
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 12;
    const [searchPlayer, setSearchPlayer] = useState('');
    const [searchTeam] = useState('');
    const [selectedTiers, setSelectedTiers] = useState([]);
    const selectedBoostTiers = [null, null, null];
    const [showMarketMenu, setShowMarketMenu] = useState(false);
    const [selectedListing, setSelectedListing] = useState(null);
    const [selectedBoosts, setSelectedBoosts] = useState([null, null, null]);
    const [removedListings, setRemovedListings] = useState([]);

    useEffect(() => {
        fetchListings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user) {
            setCurrentUserId(user?._id);
        }
    }, [user]);

    useEffect(() => {
        setSearchPlayer(sellingCardPlayer || '');
        setSelectedTiers(sellingCardTier ? [Number(sellingCardTier)] : []);
    }, [sellingCardPlayer, sellingCardTier,]);

    useEffect(() => {
        if (listings !== null) {
            submitFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listings, searchPlayer, searchTeam, selectedTiers, selectedBoosts]);

    useEffect(() => {
        const fetchData = async () => {
            if (!listings) return;

            if (view === 'cards') {
                setFilteredListings(listings.filter(listing => !removedListings.includes(listing._id)));
                setCurrentPage(1);
            }
            if (view === 'listings' && currentUserId) {
                setFilteredListings(listings.filter(listing => listing.owner._id === currentUserId && !removedListings.includes(listing._id)));
                setCurrentPage(1);
            }
            if (view === 'skins') {
                // fetch skins
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const setSortOption = debounce((option) => {
        // Save the selected option to localStorage
        localStorage.setItem('marketSortOption', option);

        // Update the dropdown button text
        const button = document.getElementById('dropdown-button');
        if (button) {
            if (option === 'boost 1' || option === 'boost 2' || option === 'boost 3') {
                button.textContent = `Sort by: ${selectedBoosts[Number(option.charAt(option.length - 1)) - 1].name}`;
            } else {
                button.textContent = `Sort by: ${option.charAt(0).toUpperCase() + option.slice(1)}`;
            }
        }

        // Update the state
        setSortBy(option);
    }, 300);

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    const sortedListings = useMemo(() => {
        const dataToSort = filteredListings;
        if (!dataToSort) return [];
        const sorted = [...dataToSort];

        const compareBoosts = (a, b, boostIndex) => {
            if (selectedBoosts[boostIndex]) {
                const boostA = a.card.boosts.find(boost => boost.boost === selectedBoosts[boostIndex]._id);
                const boostB = b.card.boosts.find(boost => boost.boost === selectedBoosts[boostIndex]._id);
                if (boostA && boostB) {
                    return sortOrder === 'asc' ? boostA.value - boostB.value : boostB.value - boostA.value;
                } else if (boostA) {
                    return -1;
                } else if (boostB) {
                    return 1;
                }
            }
            return 0;
        };

        if (sortBy === 'listed') {
            // Create a copy of the dataToSort array before reversing
            const sortedCopy = [...dataToSort];
            if (sortOrder === 'asc') {
                return sortedCopy;
            } else {
                return sortedCopy.reverse();
            }
        }

        sorted.sort((a, b) => {
            let primaryComparison = 0;

            if (sortBy === 'player') {
                primaryComparison = sortOrder === 'asc' ? a.card.player.name.localeCompare(b.card.player.name) : b.card.player.name.localeCompare(a.card.player.name);
            } else if (sortBy === 'price') {
                primaryComparison = sortOrder === 'asc' ? a.price - b.price : b.price - a.price;
            } else if (sortBy === 'rarity') {
                primaryComparison = sortOrder === 'asc' ? a.card.tier - b.card.tier : b.card.tier - a.card.tier;
            } else if (sortBy === 'boost 1') {
                primaryComparison = compareBoosts(a, b, 0);
            } else if (sortBy === 'boost 2') {
                primaryComparison = compareBoosts(a, b, 1);
            } else if (sortBy === 'boost 3') {
                primaryComparison = compareBoosts(a, b, 2);
            }

            return primaryComparison;
        });

        return sorted;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredListings, listings, sortBy, sortOrder]);

    const onListingClick = (listing) => {
        setSelectedListing(listing);
        setShowMarketMenu(true);
    };

    // Calculate the current entries to display
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = sortedListings.slice(indexOfFirstEntry, indexOfLastEntry);

    // Calculate total pages
    const totalPages = Math.ceil(sortedListings.length / entriesPerPage);

    const submitFilters = () => {
        if (!listings) {
            return;
        }

        setCurrentPage(1);

        let filteredData = listings;

        if (searchPlayer) {
            filteredData = filteredData.filter(listing =>
                listing.card.player.name.toLowerCase().includes(searchPlayer.toLowerCase())
            );
        }

        if (searchTeam) {
            filteredData = filteredData.filter(listing =>
                listing.card.team.name.toLowerCase().includes(searchTeam.toLowerCase())
            );
        }

        if (selectedTiers.length > 0) {
            filteredData = filteredData.filter(listing =>
                selectedTiers.includes(listing.card.tier)
            );
        }

        //filter out listings that don't have the selected boosts
        for (let i = 0; i < 3; i++) {
            if (selectedBoosts[i]) {
                if (selectedBoostTiers[i]) {
                    filteredData = filteredData.filter(listing =>
                        listing.card.boosts.some(boost =>
                            boost.name === selectedBoosts[i].name &&
                            boost.tier === selectedBoostTiers[i].tier
                        )
                    );
                } else {
                    filteredData = filteredData.filter(listing =>
                        listing.card.boosts.some(boost =>
                            boost.name === selectedBoosts[i].name
                        )
                    );
                }
            }
        }

        setFilteredListings(filteredData);
    };

    const handleCreateListing = async () => {
        if (!sellingCard || !sellPrice || sellPrice < 1) {
            return;
        }
        const wasListed = await addListing();
        if (wasListed) {
            //go back to inventory and refresh
            navigate('/inventory');
            window.location.reload();
        }
    };

    const handleUpdateListing = (updatedListing) => {
        // Update the listingCache
        listingCache.current.forEach((value, key) => {
            if (key.includes(updatedListing._id)) {
                listingCache.current.set(key, (
                    <div key={key} className='col-3'>
                        <div className={`cardDisplay-container market-listing ${updatedListing.owner._id === currentUserId ? 'highlight' : ''}`} onClick={() => onListingClick(updatedListing)}>
                            <p className={`market-playername ${compareListinging === updatedListing._id ? 'highlighted-card' : ''}`}>{updatedListing.card.player.name}</p>
                            <div className={`${updatedListing.owner._id === currentUserId ? 'highlighted-card' : ''}`} >
                                <Card cardID={updatedListing.card._id} market />
                            </div>
                            <div className='market-listing-price'>
                                <CoinIcon width='20px' height='20px' />
                                <p>{updatedListing.price}</p>
                            </div>
                        </div>
                    </div>
                ));
            }
        });

        // Update the filteredListings state
        setFilteredListings(prevListings => prevListings.map(listing =>
            listing._id === updatedListing._id ? updatedListing : listing
        ));

        // Update the listings state directly
        listings.forEach((listing, index) => {
            if (listing._id === updatedListing._id) {
                listings[index] = updatedListing;
            }
        });
    };


    const handleRemoveListing = (listingId) => {
        // Remove the listing from the cache
        listingCache.current.forEach((value, key) => {
            if (key.includes(listingId)) {
                listingCache.current.delete(key);
            }
        });

        // Update the filteredListings state
        setFilteredListings(prevListings => prevListings.filter(listing => listing._id !== listingId));

        // Add the removed listing ID to the removedListings state
        setRemovedListings(prevRemoved => [...prevRemoved, listingId]);

        fetchListings();
    };

    const handleSelectedBoostsChange = (boosts) => {
        setSelectedBoosts(boosts);
    };

    useEffect(() => {
        if (totalPages > 0 && currentPage > totalPages) {
            setCurrentPage(totalPages);
        } else if (currentPage < 1) {
            setCurrentPage(1);
        }
    }, [currentPage, totalPages]);

    const onBuyViewClick = () => {
        if (view === 'cards') return;
        setView('cards');
        navigate('/market');
    }

    const onListingViewClick = async () => {
        setView('listings');
    };

    return (
        <div className="main-container col-10 offset-1" style={{ minHeight: '80%' }}>
            <div className='title-container'>
                <div className='inventory-box'>
                    <div className='col-auto'>
                        <h1 className='title'>Market</h1>
                    </div>
                    <div className="view-button-container">
                        <div className="col-auto">
                            <button className={`view-button ${view === 'cards' ? 'active' : ''}`} onClick={() => onBuyViewClick()}>Buy</button>
                        </div>
                        <div className="col-auto">
                            <button className={`view-button ${view === 'listings' ? 'active' : ''}`} onClick={() => onListingViewClick()}>Your Listings</button>
                        </div>
                    </div>
                </div>
                <div className='inventory-box-filter'>
                    <div className="dropdown">
                        <button id="dropdown-button" className="dropdown-button">Select Sort Option</button>
                        <div id="dropdown-content" className="dropdown-content">
                            <p onClick={() => setSortOption('listed')}>Listed</p>
                            <p onClick={() => setSortOption('player')}>Player</p>
                            <p onClick={() => setSortOption('price')}>Price</p>
                            <p onClick={() => setSortOption('rarity')}>Rarity</p>
                            {selectedBoosts[0] && <p onClick={() => setSortOption('boost 1')}>{selectedBoosts[0].name}</p>}
                            {selectedBoosts[1] && <p onClick={() => setSortOption('boost 2')}>{selectedBoosts[1].name}</p>}
                            {selectedBoosts[2] && <p onClick={() => setSortOption('boost 3')}>{selectedBoosts[2].name}</p>}
                        </div>
                    </div>
                    <div className='sort-container'>
                        <div className='sort-svg' onClick={toggleSortOrder}
                            dangerouslySetInnerHTML={{ __html: sortOrder === 'desc' ? sortDescSVG : sortAscSVG }}
                        />
                    </div>
                </div>
            </div>
            {loading ? (
                <div className='col-10 offset-1 inventory-container'>
                    <div className='loader'></div>
                </div>
            ) : (
                (view === 'cards' || view === 'listings') && (
                    <div className="market-container">
                        {view === 'cards' &&
                            <MarketFilter
                                cachedCards={listings}
                                setFilteredCards={setFilteredListings}
                                onSelectedBoostsChange={handleSelectedBoostsChange}
                                removedListings={removedListings}
                                resetPath='/market'
                            />
                        }
                        <div className='market-content-container' style={view === 'listings' ? { width: "100%" } : {}}>
                            <div className="market-content">
                                {currentEntries.length > 0 && (
                                    currentEntries.map((listing, index) => {
                                        // Check if the Card component is already cached
                                        const cacheKey = `${listing._id}-${index}-${currentUserId}-${onListingClick}`;
                                        if (!listingCache.current.has(cacheKey)) {
                                            listingCache.current.set(cacheKey, (
                                                <div key={cacheKey} className='col-3'>
                                                    <div className={`cardDisplay-container market-listing ${listing.owner._id === currentUserId ? 'highlight' : ''}`} onClick={() => onListingClick(listing)}>
                                                        <p className={`market-playername ${compareListinging === listing._id ? 'highlighted-card' : ''}`}>{listing.card.player.name}</p>
                                                        <div className={`${listing.owner._id === currentUserId ? 'highlighted-card' : ''}`} >
                                                            <Card cardID={listing.card._id} market />
                                                        </div>
                                                        <div className='market-listing-price'>
                                                            <CoinIcon width='20px' height='20px' />
                                                            <p>{listing.price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ));
                                        }
                                        // Return the cached Card component
                                        return (
                                            <div key={cacheKey} className='col-3'>
                                                <div className={`cardDisplay-container market-listing ${listing.owner._id === currentUserId ? 'highlight' : ''}`} onClick={() => onListingClick(listing)}>
                                                    <p className={`market-playername ${compareListinging === listing._id ? 'highlighted-card' : ''}`}>{listing.card.player.name}</p>
                                                    <div className={`${listing.owner._id === currentUserId ? 'highlighted-card' : ''}`} >
                                                        <Card cardID={listing.card._id} market />
                                                    </div>
                                                    <div className='market-listing-price'>
                                                        <CoinIcon width='20px' height='20px' />
                                                        <p>{listing.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                                {sellingCard && (
                                    <div className='market-sell-container'>
                                        <div className='market-sellbar-container'>
                                            <CoinIcon width='2vh' height='2vh' />
                                            <input
                                                className='market-sellbar'
                                                type='number'
                                                placeholder='Price'
                                                min='1'
                                                max='1000000'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        // Remove any decimal points
                                                        const value = Math.max(1, Math.min(1000000, Math.floor(Number(e.target.value))));
                                                        e.target.value = value;
                                                        setSellPrice(value);
                                                    } else {
                                                        e.target.value = '';
                                                        setSellPrice(null);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <button className='default-button' onClick={handleCreateListing}>Sell</button>
                                    </div>
                                )}
                            </div>
                            <div className='pagination'>
                                <button className='default-button'
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1 || totalPages === 0}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button className='default-button'
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        {showMarketMenu && selectedListing && !sellingCard && (
                            <div className='popup-overlay' onClick={() => { setShowMarketMenu(false); setSelectedListing(null); }}>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <MarketMenu
                                        listing={selectedListing}
                                        userID={currentUserId}
                                        onClose={() => { setShowMarketMenu(false); setSelectedListing(null); }}
                                        onUpdateListing={handleUpdateListing}
                                        onRemoveListing={handleRemoveListing}
                                        setView={setView}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default Market;