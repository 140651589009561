import React, { useState, useEffect, useRef } from 'react';
import { useOpenPack } from '../hooks/useOpenPack';
import { usePack } from '../../src/hooks/usePack';
import { useBank } from '../hooks/useBank';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/PackPage.css';
import '../css/Pack.css';
import '../css/Card.css';
import '../index.css';
import Card from './Card';
import CoinIcon from './CoinIcon';
import Unknown from '../assets/Unknown.png';

const PackPage = () => {
    const { packs, hasOpenedDailyPack, dailyPackID } = usePack();
    const [showDailyPack, setShowDailyPack] = useState(true);
    const { fetchCoins, subtractCoins } = useBank();
    const [selectedPack, setSelectedPack] = useState(null);
    const [openedCardsID, setOpenedCardsID] = useState([]);
    const cardRefs = useRef([]);
    const [lockOpening, setLockOpening] = useState(false);
    const [coins, setCoins] = useState(0);
    const [timeLeft, setTimeLeft] = useState('');
    const [isOpening, setIsOpening] = useState(true);
    const [allCardsOpened, setAllCardsOpened] = useState(false); // State to track if all cards are opened
    const timers = useRef([]);

    const openSelectPackPopUp = (pack) => {
        setSelectedPack(pack);
    };

    const closeSelectPackPopUp = () => {
        setSelectedPack(null);
    };

    const openPackOpeningPopUp = (cards) => {
        setIsOpening(true);
        setOpenedCardsID(cards);
        setAllCardsOpened(false); // Reset the state when opening a new pack
    };

    const closePackOpeningPopUp = () => {
        setOpenedCardsID([]);
        setLockOpening(false);
        clearTimers();
    };

    const quickOpen = () => {
        setLockOpening(false);
        setIsOpening(false);
        clearTimers();
        setAllCardsOpened(true); // Set the state to true when quick open is used
    };

    const clearTimers = () => {
        timers.current.forEach(timer => clearTimeout(timer));
        timers.current = [];
    };

    const { openPack, openDailyPack, data, loading } = useOpenPack();

    const handleOpenPack = async (tier) => {
        if (lockOpening === false) {
            if (await subtractCoins(tier.price) === false) {
                return;
            }
            try {
                await openPack(selectedPack._id, tier.type);
            } catch (error) {
                setLockOpening(false);
                console.error('Error opening pack:', error);
            }
            setLockOpening(true);
            fetchCoins().then((result) => {
                setCoins(result);
            })
        }
    };

    const handleOpenDailyPack = async (dailyPackID) => {
        if (lockOpening === false) {
            try {
                await openDailyPack(dailyPackID);
                setShowDailyPack(false);
            } catch (error) {
                setLockOpening(false);
                console.error('Error opening pack:', error);
            }
            setLockOpening(true);
        }
    };

    useEffect(() => {
        fetchCoins().then((result) => {
            setCoins(result);
        });
    });

    useEffect(() => {
        if (selectedPack && selectedPack.expireDate) {
            const targetDate = new Date(selectedPack.expireDate).getTime();

            const updateTimer = () => {
                const now = new Date().getTime();
                const distance = targetDate - now;

                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            };
            updateTimer();
            const interval = setInterval(updateTimer, 1000);

            return () => clearInterval(interval);
        } else { setTimeLeft('') }
        // eslint-disable-next-line
    }, [selectedPack]);

    useEffect(() => {
        if (data && data.cards && data.cards[0]) {
            openPackOpeningPopUp(data.cards);
            closeSelectPackPopUp();
        }
    }, [data]);

    useEffect(() => {
        if (openedCardsID.length > 0) {
            // Open the first card after half a second
            const firstTimer = setTimeout(() => {
                if (cardRefs.current[0]) {
                    cardRefs.current[0].openCard();
                }
                if (openedCardsID.length === 1) {
                    setLockOpening(false);
                    setAllCardsOpened(true); // Set the state to true when all cards are opened
                }
            }, 500); // 1 second delay for the first card
            timers.current.push(firstTimer);

            // Open the remaining cards with a delay
            openedCardsID.slice(1).forEach((cardID, index) => {
                const timer = setTimeout(() => {
                    if (cardRefs.current[index + 1]) { // Adjust index to account for the first card
                        cardRefs.current[index + 1].openCard();
                    }
                    // Unlock opening after the last card is opened
                    if (index === openedCardsID.length - 2) {
                        setLockOpening(false);
                        setAllCardsOpened(true); // Set the state to true when all cards are opened
                    }
                }, 1000 + (index + 1) * 3500);
                timers.current.push(timer);
            });
        }
    }, [openedCardsID]);

    useEffect(() => {
        const packContent = document.querySelector('.OpenPack-content');
        if (packContent) {
            const handleScroll = (event) => {
                if (event.deltaY !== 0) {
                    packContent.scrollLeft += event.deltaY;
                    event.preventDefault();
                }
            };
            packContent.addEventListener('wheel', handleScroll);
            return () => {
                packContent.removeEventListener('wheel', handleScroll);
            };
        }
    }, [openPack]);

    useEffect(() => {
        justifyContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedCardsID, allCardsOpened]);

    const justifyContent = () => {
        const packContainer = document.querySelector('.OpenPack-content');

        const checkCardContainer = () => {
            const cardContainer = document.getElementById('card');
            if (packContainer && cardContainer) {
                const totalCardsWidth = openedCardsID.length * cardContainer.clientWidth;
                const isOverflowing = totalCardsWidth > packContainer.clientWidth;

                if (isOverflowing) {
                    packContainer.style.justifyContent = 'flex-start';
                } else {
                    packContainer.style.justifyContent = 'center';
                }

                // Clear the interval once the cardContainer is found and processed
                clearInterval(intervalId);
            }
        };

        // Retry every second until the cardContainer is found
        const intervalId = setInterval(checkCardContainer, 1000);
    };

    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp);
        return date.toLocaleDateString('da-DK', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    };

    return (
        <div className='main-container col-10 offset-1'>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader'></div>
                </div>
            )}
            <div className='title-container'>
                <h1 className='title '>Packs</h1>
            </div>

            <div className='packPage-packs-container'>
                {!hasOpenedDailyPack && dailyPackID && showDailyPack && (
                    <div className='col-3 pack-container' onClick={() => handleOpenDailyPack(dailyPackID)}>
                        <h1 className='title-pack'>Daily Pack</h1>
                        <div className='card-container'>
                            <div className='bronze-pack active static' id="card">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.3 427.3">
                                    <defs>
                                        <clipPath id="svgPath">
                                            <path d="M265.3 53.9a33.3 33.3 0 0 1-17.8-5.5 32 32 0 0 1-13.7-22.9c-.2-1.1-.4-2.3-.4-3.4 0-1.3-1-1.5-1.8-1.9a163 163 0 0 0-31-11.6A257.3 257.3 0 0 0 133.7 0a254.9 254.9 0 0 0-67.1 8.7 170 170 0 0 0-31 11.6c-.8.4-1.8.6-1.8 1.9 0 1.1-.2 2.3-.4 3.4a32.4 32.4 0 0 1-13.7 22.9A33.8 33.8 0 0 1 2 53.9c-1.5.1-2.1.4-2 2v293.9c0 3.3 0 6.6.4 9.9a22 22 0 0 0 7.9 14.4c3.8 3.2 8.3 5.3 13 6.8 12.4 3.9 24.8 7.5 37.2 11.5a388.7 388.7 0 0 1 50 19.4 88.7 88.7 0 0 1 25 15.5v.1-.1c7.2-7 16.1-11.3 25-15.5a427 427 0 0 1 50-19.4l37.2-11.5c4.7-1.5 9.1-3.5 13-6.8 4.5-3.8 7.2-8.5 7.9-14.4.4-3.3.4-6.6.4-9.9V231.6 60.5v-4.6c.4-1.6-.3-1.9-1.7-2z" />
                                        </clipPath>
                                    </defs>
                                    <image href={`${process.env.PUBLIC_URL}/cardBackground.png`} x="0" y="0" width="100%" height="100%" clipPath="url(#svgPath)" preserveAspectRatio="none"></image>
                                    <image href={Unknown} x="20%" y="0" width="60%" height="60%" clipPath="url(#svgPath)" />
                                </svg>
                                <div className="pack-bottom">
                                    <div className="name">
                                        Daily Pack
                                    </div>
                                    <div className="stats">
                                        <div>
                                            <ul>
                                                {/* Doesn't update from db */}
                                                <li><span>Bronze</span><span className=''>90%</span></li>
                                                <li><span>Silver </span><span className=''>8%</span></li>
                                                <li><span>Gold</span><span className=''>1.5%</span></li>
                                                <li><span>Legendary</span><span className=''>0.5%</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className='price-pack'>Free</h1>
                    </div>
                )}
                {packs.length > 0 ? (
                    packs.map((pack, index) => (
                        <div key={index} className='col-3 pack-container' onClick={() => openSelectPackPopUp(pack)}>
                            <h1 className='title-pack'>{pack.name}</h1>
                            <div>
                                <img className='pack-image' src={pack.image} alt={pack.name} />
                            </div>
                            <h1 className='price-pack'> Offer ends: {formatDate(pack.expireDate)} </h1>
                        </div>
                    ))
                ) : (
                    <div className='no-packs-container'>
                        <p>NO PACKS AVAILABLE AT THE MOMENT</p>
                    </div>
                )}
            </div>

            {selectedPack && (
                <div className='popup-overlay' onClick={closeSelectPackPopUp}>
                    <div className='SelectPack-container' onClick={(e) => e.stopPropagation()}>
                        <button className="overlay-close" onClick={closeSelectPackPopUp}>X</button>
                        <h1 className='title-pack'>{selectedPack.name}</h1>
                        <h1 className='price-pack'> Offer ends: {timeLeft} </h1>
                        <div className='SelectPack-content'>
                            {selectedPack.tiers.map((tier, index) => (
                                <div key={index} className='col-3 pack-tier-container' onClick={() => handleOpenPack(tier)}>
                                    <div className='card-container'>
                                        <div className={`${tier.type === 1 ? 'bronze-pack' : tier.type === 2 ? 'silver-pack' : 'gold-pack'} active static`} id="card">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.3 427.3">
                                                <clipPath id="svgPath">
                                                    <path d="M265.3 53.9a33.3 33.3 0 0 1-17.8-5.5 32 32 0 0 1-13.7-22.9c-.2-1.1-.4-2.3-.4-3.4 0-1.3-1-1.5-1.8-1.9a163 163 0 0 0-31-11.6A257.3 257.3 0 0 0 133.7 0a254.9 254.9 0 0 0-67.1 8.7 170 170 0 0 0-31 11.6c-.8.4-1.8.6-1.8 1.9 0 1.1-.2 2.3-.4 3.4a32.4 32.4 0 0 1-13.7 22.9A33.8 33.8 0 0 1 2 53.9c-1.5.1-2.1.4-2 2v293.9c0 3.3 0 6.6.4 9.9a22 22 0 0 0 7.9 14.4c3.8 3.2 8.3 5.3 13 6.8 12.4 3.9 24.8 7.5 37.2 11.5a388.7 388.7 0 0 1 50 19.4 88.7 88.7 0 0 1 25 15.5v.1-.1c7.2-7 16.1-11.3 25-15.5a427 427 0 0 1 50-19.4l37.2-11.5c4.7-1.5 9.1-3.5 13-6.8 4.5-3.8 7.2-8.5 7.9-14.4.4-3.3.4-6.6.4-9.9V231.6 60.5v-4.6c.4-1.6-.3-1.9-1.7-2z" />
                                                </clipPath>
                                                <image src={`${process.env.PUBLIC_URL}/cardBackground.png`} x="0" y="0" width="100%" height="100%" clipPath="url(#svgPath)" preserveAspectRatio="none"></image>
                                                <image href={selectedPack.image} x="20%" y="0" width="60%" height="60%" clipPath="url(#svgPath)" />
                                            </svg>
                                            <div className="pack-bottom">
                                                <div className="name">
                                                    {tier.type === 1 ? 'Bronze' : tier.type === 2 ? 'Silver' : 'Gold'} Pack
                                                </div>
                                                <div className="stats">
                                                    <div>
                                                        <ul>
                                                            <li><span>Bronze</span><span className=''>{tier.odds.bronze}%</span></li>
                                                            <li><span>Silver </span><span className=''>{tier.odds.silver}%</span></li>
                                                            <li><span>Gold</span><span className=''>{tier.odds.gold}%</span></li>
                                                            <li><span>Legendary</span><span className=''>{tier.odds.legendary}%</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                    <h1 style={{ color: coins < tier.price ? 'var(--lightred)' : coins >= tier.price ? 'var(--lightgreen)' : 'inherit' }} className='price-pack'> <CoinIcon width="18px" height="18px" /> {tier.price && tier.price}</h1>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {openedCardsID && openedCardsID.length > 0 && (
                <div className='popup-overlay'>
                    <div className='OpenPack-container'>
                        <div className='OpenPack-content col-10'>
                            {openedCardsID.map((card, index) => (
                                <div key={index} className='cardDisplay-container' style={{ paddingBottom: 0, marginBottom: 0 }}>
                                    <Card ref={(el) => (cardRefs.current[index] = el)} cardID={card._id} size={"small"} isOpening={isOpening} />
                                </div>
                            ))}
                        </div>
                        <div className='OpenPack-footer'>
                            <button className='default-button close-button' onClick={closePackOpeningPopUp}>Close</button>
                            {!allCardsOpened && (
                                <button className='default-button close-button' onClick={quickOpen}>Quick Open</button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PackPage;