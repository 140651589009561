import { useAuthContext } from "./useAuthContext";

export const useLogOut = () => {
    const { dispatch } = useAuthContext();

    const logOut = async () => {
        // Call the logout endpoint
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/logout`, {
            method: 'POST',
            credentials: 'include' // Include cookies in the request
        });

        // Update auth context
        dispatch({ type: 'LOGOUT' });
    }

    return { logOut };
}